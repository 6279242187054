<template>
  <div>
    <b-alert v-model="isDraftAvailable" v-height-fade.appear variant="warning">
      <div class="alert-body">
        <b-row>
          <b-col class="align-self-center">
            Do you want to restore the draft data?
          </b-col>
          <b-col class="align-self-center text-right">
            <b-button @click="removeDraft" variant="outline-danger" class="mr-2"
              >Ignore</b-button
            >
            <b-button @click="restoreDraft" variant="primary">Restore</b-button>
          </b-col>
        </b-row>
      </div>
    </b-alert>
    <b-tabs>
      <b-tab active title="English">
        <b-card title="Government Resolution Page">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Page Title">
                <b-form-input
                  v-model="page.pageTitle"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Description">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Description">
                <b-form-input
                  v-model="page.description"
                  placeholder="Description"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Resolutions & Circulars">
          <b-form class="repeater-form" @submit.prevent="repeatResolutionSection(null)">
            <b-row
              v-for="(GR, index) in page.governmentResolutions"
              :key="GR.id"
              ref="resolutionrow"
            >
              <b-col cols="10">
                <b-form-group label="Title">
                  <b-form-input
                    v-model="page.governmentResolutions[index].title"
                    placeholder="Section Title"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" class="mb-50">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeResolutionsSection(null, index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Remove</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <b-card style="background: #f5f5f5" title="Resolution Series">
                  <b-row>
                    <b-col cols="12">
                      <b-form
                        class="repeater-form"
                        @submit.prevent="repeatResolutions(null, index)"
                      >
                        <b-row
                          v-for="(resolution, index1) in page.governmentResolutions[index]
                            .resolutions"
                          :key="index1"
                          ref="resolutionrow"
                        >
                          <b-col cols="5">
                            <b-form-group label="Title">
                              <b-form-input
                                v-model="
                                  page.governmentResolutions[index].resolutions[
                                    index1
                                  ].title
                                "
                                placeholder="Resolution Title"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="5">
                            <b-form-group label="Download Url">
                              <b-form-input
                                v-model="
                                  page.governmentResolutions[index].resolutions[
                                    index1
                                  ].downloadUrl
                                "
                                placeholder="Download Url"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col lg="2" md="2" class="mb-50">
                            <b-button
                              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                              variant="outline-danger"
                              class="mt-0 mt-md-2"
                              @click="
                                removeResolutions(null, index, index1)
                              "
                            >
                              <feather-icon icon="XIcon" class="mr-25" />
                              <span>Remove</span>
                            </b-button>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12" class="mt-1"
                            ><b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              @click="repeatResolutions(null, index)"
                            >
                              <feather-icon icon="PlusIcon" class="mr-25" />
                              <span>{{
                                page.governmentResolutions[index].resolutions
                                  .length === 0
                                  ? "Add Series Resolutions"
                                  : "Add New Series Resolutions"
                              }}</span>
                            </b-button></b-col
                          >
                        </b-row>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatResolutionSection(null)"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.governmentResolutions.length === 0
                      ? "Add Main Resolution"
                      : "Add New Resolution"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>
      </b-tab>
      <!-- Hindi Translation -->
      <b-tab title="Hindi">
        <b-card title="Government Resolution Page">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Page Title">
                <b-form-input
                  v-model="page.translations.hi.pageTitle"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Description">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Description">
                <b-form-input
                  v-model="page.translations.hi.description"
                  placeholder="Description"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Resolutions & Circulars">
          <b-form class="repeater-form" @submit.prevent="repeatResolutionSection('hi')">
            <b-row
              v-for="(resolution, index) in page.translations.hi.governmentResolutions"
              :key="resolution.id"
              ref="resolutionrow"
            >
              <b-col cols="10">
                <b-form-group label="Title">
                  <b-form-input
                    v-model="page.translations.hi.governmentResolutions[index].title"
                    placeholder="Section Title"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" class="mb-50">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeResolutionsSection('hi', index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Remove</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <b-card style="background: #f5f5f5" title="Resolution Series">
                  <b-row>
                    <b-col cols="12">
                      <b-form
                        class="repeater-form"
                        @submit.prevent="repeatResolutions('hi', index)"
                      >
                        <b-row
                          v-for="(resolution, index1) in page.translations.hi
                            .governmentResolutions[index].resolutions"
                          :key="resolution.id"
                          ref="resolutionrow"
                        >
                          <b-col cols="5">
                            <b-form-group label="Title">
                              <b-form-input
                                v-model="
                                  page.translations.hi.governmentResolutions[index]
                                    .resolutions[index1].title
                                "
                                placeholder="Resolution Title"
                              />
                            </b-form-group>
                          </b-col>
                           <b-col cols="5">
                            <b-form-group label="Download Url">
                              <b-form-input
                                v-model="
                                  page.translations.hi.governmentResolutions[index].resolutions[
                                    index1
                                  ].downloadUrl
                                "
                                placeholder="Download Url"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col lg="2" md="2" class="mb-50">
                            <b-button
                              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                              variant="outline-danger"
                              class="mt-0 mt-md-2"
                              @click="
                                removeResolutions('hi', index, index1)
                              "
                            >
                              <feather-icon icon="XIcon" class="mr-25" />
                              <span>Remove</span>
                            </b-button>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12" class="mt-1"
                            ><b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              @click="repeatResolutions('hi', index)"
                            >
                              <feather-icon icon="PlusIcon" class="mr-25" />
                              <span>{{
                                page.translations.hi.governmentResolutions[index]
                                  .resolutions.length === 0
                                  ? "Add Series Resolutions"
                                  : "Add New Series Resolutions"
                              }}</span>
                            </b-button></b-col
                          >
                        </b-row>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatResolutionSection('hi')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.translations.hi.governmentResolutions.length === 0
                      ? "Add Main Resolution"
                      : "Add New Resolution"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>
      </b-tab>
      <!-- Marathi Translation -->
      <b-tab title="Marathi">
        <b-card title="Government Resolution Page">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Page Title">
                <b-form-input
                  v-model="page.translations.mr.pageTitle"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Description">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Description">
                <b-form-input
                  v-model="page.translations.mr.description"
                  placeholder="Description"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Resolutions & Circulars">
          <b-form class="repeater-form" @submit.prevent="repeatResolutionSection('mr')">
            <b-row
              v-for="(resolution, index) in page.translations.mr.governmentResolutions"
              :key="resolution.id"
              ref="resolutionrow"
            >
              <b-col cols="10">
                <b-form-group label="Title">
                  <b-form-input
                    v-model="page.translations.mr.governmentResolutions[index].title"
                    placeholder="Section Title"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" class="mb-50">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeResolutionsSection('mr', index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Remove</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <b-card style="background: #f5f5f5" title="Resolution Series">
                  <b-row>
                    <b-col cols="12">
                      <b-form
                        class="repeater-form"
                        @submit.prevent="repeatResolutions('mr', index)"
                      >
                        <b-row
                          v-for="(resolution, index1) in page.translations.mr
                            .governmentResolutions[index].resolutions"
                          :key="resolution.id"
                          ref="resolutionrow"
                        >
                          <b-col cols="5">
                            <b-form-group label="Title">
                              <b-form-input
                                v-model="
                                  page.translations.mr.governmentResolutions[index]
                                    .resolutions[index1].title
                                "
                                placeholder="Resolution Title"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="5">
                            <b-form-group label="Download Url">
                              <b-form-input
                                v-model="
                                  page.translations.mr.governmentResolutions[index].resolutions[
                                    index1
                                  ].downloadUrl
                                "
                                placeholder="Download Url"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col lg="2" md="2" class="mb-50">
                            <b-button
                              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                              variant="outline-danger"
                              class="mt-0 mt-md-2"
                              @click="
                                removeResolutions('mr', index, index1)
                              "
                            >
                              <feather-icon icon="XIcon" class="mr-25" />
                              <span>Remove</span>
                            </b-button>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12" class="mt-1"
                            ><b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              @click="repeatResolutions('mr', index)"
                            >
                              <feather-icon icon="PlusIcon" class="mr-25" />
                              <span>{{
                                page.translations.mr.governmentResolutions[index].resolutions.length === 0
                                  ? "Add Series Resolutions"
                                  : "Add New Series Resolutions"
                              }}</span>
                            </b-button></b-col
                          >
                        </b-row>
                      </b-form>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatResolutionSection('mr')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.translations.mr.governmentResolutions.length === 0
                      ? "Add Main Resolution"
                      : "Add New Resolution"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>
      </b-tab>
    </b-tabs>
     <b-card>
      <b-row>
        <b-col cols="6">
          <b-button size="lg" @click="saveDraft" block variant="outline-info"
            >Create Draft</b-button
          >
        </b-col>
        <b-col cols="6">
          <b-button size="lg" @click="savePage" block variant="primary"
            >Save Page</b-button
          >
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BTabs,
  BTab,
  BButton,
  BFormTextarea,
  BImg,
  BAlert,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import { heightFade } from "@core/directives/animations";
import { getUserToken } from "@/auth/utils";
import vSelect from "vue-select";
import ComponentMediaSelector from "@/views/Gallery/ComponentMediaSelector";
import axios from "axios";

export default {
  directives: {
    "height-fade": heightFade,
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    BTabs,
    BTab,
    BButton,
    BFormTextarea,
    ComponentMediaSelector,
    BImg,
    BAlert,
  },
  data() {
    return {
      isDraftAvailable: false,
      tenders: [],
      page: {
        pageTitle: "",
        governmentResolutions: [],
        translations: {
          hi: {
            pageTitle: "",
            governmentResolutions: [],

          },
          mr: {
            pageTitle: "",
            governmentResolutions: [],
          },
        },
      },
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    repeatResolutionsList(lang = undefined, index, index1) {
      if (lang) {
        this.page.translations[lang].governmentResolutions[index].resolutions[
          index1
        ].list.push({
          title: "",
          list: [],
        });
      } else {
        this.page.governmentResolutions[index].resolutions[index1].list.push({
          title: "",
          list: [],
        });
      }
    },
    repeatResolutions(lang = undefined, index) {
      if (lang) {
        this.page.translations[lang].governmentResolutions[index].resolutions.push({
          title: "",
          list: [],
        });
      } else {
        this.page.governmentResolutions[index].resolutions.push({
          title: "",
          list: [],
        });
      }
    },
    removeDocumentList(lang = undefined, index, index1, index2) {
      if (lang) {
        this.page.translations[lang].governmentResolutions[index].resolutions[
          index1
        ].list.splice(index2, 1);
      } else {
        this.page.governmentResolutions[index].resolutions[index1].list.splice(
          index2,
          1
        );
      }
    },
    removeResolutions(lang = undefined, index, index1) {
      if (lang) {
        this.page.translations[lang].governmentResolutions[
          index
        ].resolutions.splice(index1, 1);
      } else {
        this.page.governmentResolutions[index].resolutions.splice(index1, 1);
      }
    },
    repeatResolutionSection(lang = undefined) {
      if (lang) {
        this.page.translations[lang].governmentResolutions.push({
          title: "",
          resolutions: [],
        });
      } else {
        this.page.governmentResolutions.push({
          title: "",
          resolutions: [],
        });
      }
    },
    saveDraft() {
      localStorage.setItem("resolution-page-data", JSON.stringify(this.page));
      this.makeToast("success", "Success", `Draft Saved!`);
    },
    restoreDraft() {
      const page = localStorage.getItem("resolution-page-data");
      this.page = JSON.parse(page);
    },
    removeResolutionsSection(lang = undefined, index) {
      if (lang) {
        this.page.translations[lang].governmentResolutions.splice(index, 1);
      } else {
        this.page.governmentResolutions.splice(index, 1);
      }
    },
    removeDraft() {
      localStorage.removeItem("resolution-page-data");
      this.isDraftAvailable = false;
    },
    getPage() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/pages/gr/get`)
        .then((response) => {
          console.log(response)
          if(response.data[0]) {
              this.page = response.data[0];
          }
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    savePage() {
      console.log(this.page);
      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/pages/gr/save`, this.page, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          console.log('res', response.data)
           this.makeToast(
                "success",
                "Success",
                `${this.page.pageTitle} has been updated successfully!`
              );
        })
        .catch((error) => {
          console.log(error.response);
           this.makeToast(
                "danger",
                "Error",
                "Error while updating the event!"
              );
        });
    },
  },
  mounted() {
    if (localStorage.getItem("resolution-page-data")) {
      this.isDraftAvailable = true;
    }
    this.getPage();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>